import { BadgeCheck, Blocks, Paintbrush } from "lucide-react";
import React from "react";

const Steps = () => {
  return (
    <div className="container mx-auto px-4 md:px-6 flex flex-col md:flex-row justify-between md:mt-16 gap-16 md:gap-24">
      <div className="flex flex-row gap-4">
        <div className="flex justify-center items-center mb-2 shrink-0">
          <span className="content-center flex justify-center items-center bg-gradient-to-t from-yellow-400 to-yellow-300 text-white p-3 w-16 h-16 rounded-lg">
            <Paintbrush size={32} />
          </span>
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-kantumruy font-semibold text-gray-900">
            Design
          </h3>
          <p className="text-wrap text-sm font-poppins text-gray-500">
            Nous nous mettons d'accord sur un design pour que votre site reflète
            votre image.
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex justify-center items-center mb-2 shrink-0">
          <span className="content-center flex justify-center items-center bg-gradient-to-t from-orange-500 to-orange-400 text-white p-3 w-16 h-16 rounded-lg">
            <Blocks size={32} />
          </span>
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-kantumruy font-semibold text-gray-900">
            Développement
          </h3>
          <p className="text-wrap text-sm font-poppins text-gray-500">
            Nous développons de votre site, vous n'avez pas a vous occuper des
            parties techniques.
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex justify-center items-center mb-2 shrink-0">
          <span className="content-center flex justify-center items-center bg-gradient-to-t from-sky-500 to-sky-400 text-white p-3 w-16 h-16 rounded-lg">
            <BadgeCheck size={32} />
          </span>
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-kantumruy font-semibold text-gray-900">
            Validation
          </h3>
          <p className="text-wrap text-sm font-poppins text-gray-500">
            Vous validez le site ou demandez autant de changements que vous
            voulez.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Steps;
