import { Mail } from "lucide-react";
import React from "react";

const Contact = () => {
  return (
    <section
      className="flex justify-center items-center mx-4 md:mx-0 py-8 md:py-16 mb-24"
      id="contact"
    >
      <div className="relative bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] rounded-3xl w-full py-16 md:py-32 px-8 md:px-24 flex flex-col md:flex-row items-center justify-center md:justify-evenly">
        <h2 className="text-white font-kantumruy font-semibold text-3xl md:text-4xl mb-8 md:mb-0 md:mr-6 text-center md:text-left w-full md:w-1/2">
          Commencer un projet avec nous ?
        </h2>
        <a href="mailto:hello@ethan-dh.fr">
          <button className="font-poppins bg-white text-blue-500 font-medium flex items-center px-6 md:px-8 py-3 md:py-4 rounded-lg text-lg md:text-xl hover:scale-110 duration-200">
            Envoyer un mail
            <Mail
              color={"#3b82f6"}
              strokeWidth={2.5}
              className="w-5 h-5 ml-3"
            />
          </button>
        </a>
      </div>
    </section>
  );
};

export default Contact;
