import React, { useState } from "react";
import logo from "../assets/small_logo.png";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="w-full p-4 z-50 flex items-center justify-between relative">
      <div className="logo w-12 h-12">
        <img src={logo} alt="Logo" />
      </div>
      <button
        className="md:hidden text-gray-800 z-50" // Added z-50 to ensure the button is on top
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {isOpen ? <X className="w-8 h-8" /> : <Menu className="w-8 h-8" />}
      </button>
      <ul
        className={`${
          isOpen ? "block" : "hidden"
        } h-screen text-3xl md:text-base py-48 md:py-0 md:flex flex flex-col md:flex-row z-40 gap-12 items-center absolute md:static bg-white md:bg-transparent w-full left-0 top-0 md:top-auto md:w-auto md:h-auto`}
      >
        <li>
          <a
            href="/"
            className="hover:text-blue-500 font-medium md:font-normal "
            onClick={closeMenu}
          >
            Accueil
          </a>
        </li>
        <li>
          <a
            href="/#a-propos"
            className="hover:text-blue-500 font-medium md:font-normal "
            onClick={closeMenu}
          >
            A propos
          </a>
        </li>
        <li>
          <a
            href="/#tarifs"
            className="hover:text-blue-500 font-medium md:font-normal "
            onClick={closeMenu}
          >
            Tarifs
          </a>
        </li>
        <li>
          <a
            href="/#portfolio"
            className="hover:text-blue-500 font-medium md:font-normal "
            onClick={closeMenu}
          >
            Portfolio
          </a>
        </li>
        {/* <li>
          <a
            href="#temoignages"
            className="hover:text-blue-500 font-medium md:font-normal "
            onClick={closeMenu}
          >
            Témoignages
          </a>
        </li> */}
        <li>
          <a
            href="/#contact"
            className="hover:text-blue-500 font-medium md:font-normal text-blue-500 md:text-gray-900"
            onClick={closeMenu}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
