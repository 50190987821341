import React from "react";
import logo from "../assets/small_logo.png";
import ia from "../assets/aipng.png";
import zoo from "../assets/zoo.png";
import portfolio from "../assets/portfolio.png";
import resto from "../assets/resto.png";
import { ChevronLeft } from "lucide-react";
import Footer from "../sections/Footer";

const cards = [
  {
    title: "Site IA",
    description: "Site d'une IA fictive.",
    image: ia,
    link: "/sites/ia",
  },
  {
    title: "Site restaurant",
    description: "Site d'un restaurant fictif.",
    image: resto,
    link: "/sites/restaurant",
  },
  {
    title: "Le temps d'un instant",
    description: "Site pour une zoothérapeute.",
    image: zoo,
  },
  {
    title: "Portfolio",
    description: "Un portfolio moderne que j'ai commencé.",
    image: portfolio,
  },
];

const Portfolio = () => {
  return (
    <>
      <div className="2xl:w-2/3 lg:w-5/6  mx-auto p-6">
        {/* Logo at the top center */}
        <div className="absolute inset-x-0 -top-20 md:top-56 bottom-0 flex justify-center items-center">
          <div className="hidden md:block w-96 h-48 scale-[200%] -z-10 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full blur-3xl opacity-35"></div>
        </div>
        <div className="flex items-center justify-between mb-8 md:mb-32">
          <a
            href="/"
            className="hover:text-blue-500 font-medium md:font-normal flex flex-row items-center z-20 relative"
          >
            <ChevronLeft />
          </a>

          <h2 className="text-gray-900 texxl md:text-4xl font-kantumruy font-semibold">
            Portfolio
          </h2>
          <a href="/">
            <img src={logo} alt="Logo" className="h-8" />{" "}
            {/* Adjust height as needed */}
          </a>
        </div>

        {/* Card grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cards.map((card, index) => (
            <a
              key={index}
              className={`flex flex-col group bg-white border shadow-sm rounded-3xl overflow-hidden focus:outline-none focus:shadow-lg transition ${
                card.link ? "cursor-pointer" : "cursor-not-allowed"
              }`}
              href={card.link || "/"}
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative pt-[56.25%] rounded-t-xl overflow-hidden">
                {/* 16:9 Aspect Ratio */}
                <img
                  className="absolute top-0 start-0 w-full h-full object-cover group-hover:scale-105 group-focus:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
                  src={card.image}
                  alt={card.title}
                />
              </div>
              <div className="p-4 md:p-5">
                <h3 className="text-lg font-bold text-gray-800">
                  {card.title}
                </h3>
                <p className="mt-1 text-gray-600">{card.description}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="mt-8 2xl:w-2/3 lg:w-5/6  mx-auto">
        <Footer />
      </div>
    </>
  );
};

export default Portfolio;
