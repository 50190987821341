import React from "react";
import mac from "../assets/mac.png";

const Header = () => {
  return (
    <header className="py-8 md:py-16 md:my-16">
      <div className="hidden md:block absolute w-96 h-64 scale-[2] bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] rounded-full blur-3xl md:blur-2xl -z-20 md:-inset-x-24 mt-52 md:mt-96 opacity-50 md:opacity-80"></div>
      <div className="container mx-auto flex flex-col md:flex-row items-center mb-12">
        <div className="flex-1 md:text-left mb-20 md:mb-0">
          <h1 className="font-kantumruy text-5xl text-center md:text-start md:text-7xl font-semibold text-gray-900 mb-4">
            Mettez votre savoir-faire en lumière,{" "}
            <span className="bg-gradient-to-tr from-[#527FF6] from-[-20%] via-[#E16DD9] via-40% to-[#FFCC70] to-[130%] bg-clip-text text-transparent">
              sans stress
            </span>{" "}
            !
          </h1>
          <p className="text-center md:text-start text-xl text-gray-600 md:text-gray-500 mb-6 mx-4 md:mx-0">
            Nous vous offrons une présence en ligne sans tracas.
          </p>

          <div className="flex w-screen md:w-auto flex-col md:flex-row gap-4 items-center justify-center md:justify-start">
            <a href="#contact">
              <button className="w-[80vw] md:w-auto text-center group inline-flex items-center justify-center hover:shadow-lg hover:shadow-blue-500/50 duration-500 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white py-3 px-6 rounded-lg text-lg">
                Commencer
              </button>
            </a>
            <a href="#a-propos">
              <button
                type="button"
                className="w-[80vw] md:w-auto text-center font-poppins py-3 px-4 h-full inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-transparent text-gray-800 hover:underline"
              >
                En savoir plus
              </button>
            </a>
          </div>
        </div>
        <div className="flex-1 flex justify-center md:justify-end">
          <img
            src={mac}
            alt="Laptop showing website"
            className="transform md:scale-[1.2] w-full"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
