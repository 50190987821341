import React, { useEffect, useRef, useState } from "react";
import image1 from "../assets/animaux.png";
import image2 from "../assets/aipng.png";
import image3 from "../assets/apex.png";
import image4 from "../assets/ecom.png";
import image5 from "../assets/qs.png";
import image6 from "../assets/portfolio.png";
import image7 from "../assets/resto.png";
import image8 from "../assets/zoo.png";
import { ExternalLink } from "lucide-react";

const Gallery = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const galleryRef = useRef(null);

  const handleScroll = () => {
    if (galleryRef.current) {
      const galleryTop = galleryRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (galleryTop <= windowHeight && galleryTop >= -windowHeight) {
        const relativeScrollY = windowHeight - galleryTop;
        setScrollPos(relativeScrollY);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      id="portfolio"
      ref={galleryRef}
      className="relative overflow-hidden mx-auto mt-12 md:mt-44"
    >
      {/* First Row of Images */}
      <div
        className="flex space-x-4 overflow-hidden min-w-max"
        style={{
          transform: `translateX(${-scrollPos * 0.2}px)`,
          transition: "transform 0.1s ease-out",
        }}
      >
        {[image1, image2, image3, image4].map((src, index) => (
          <div
            key={index}
            className="w-40 h-28 sm:w-64 sm:h-56 md:w-80 md:h-64 lg:w-96 lg:h-72 flex-shrink-0"
          >
            <img
              src={src}
              alt={`gallery image ${index + 1}`}
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        ))}
      </div>

      {/* Second Row of Images with Initial Offset */}
      <div
        className="flex space-x-4 overflow-hidden mt-8 min-w-max"
        style={{
          transform: `translateX(calc(${scrollPos * 0.1}px - 200px))`,
          transition: "transform 0.1s ease-out",
        }}
      >
        {[image5, image6, image7, image8].map((src, index) => (
          <div
            key={index}
            className="w-40 h-28 sm:w-64 sm:h-56 md:w-80 md:h-64 lg:w-96 lg:h-72 flex-shrink-0"
          >
            <img
              src={src}
              alt={`gallery image ${index + 1}`}
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        ))}
      </div>

      {/* Fade effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 bottom-0 left-0 w-12 sm:w-16 lg:w-24 bg-gradient-to-l from-transparent to-white"></div>
        <div className="absolute top-0 bottom-0 right-0 w-12 sm:w-16 lg:w-24 bg-gradient-to-r from-transparent to-white"></div>
      </div>

      {/* Centered Button */}
      <div className="flex justify-center mt-12">
        <button className="">
          <a
            href="/portfolio"
            className="text-neutral-900 flex flex-row gap-4 items-center px-6 py-3 bg-gray-300/80 font-semibold rounded-lg hover:scale-105 transition-all active:scale-100"
          >
            Voir notre portfolio
            <ExternalLink />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Gallery;
