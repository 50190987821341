import Navbar from "../components/Navbar";
import APropos from "../sections/APropos";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";
import Gallery from "../sections/Gallery";
import Header from "../sections/Header";
import Portfolio from "../sections/Portfolio";
import Pricing from "../sections/Pricing";
import Steps from "../sections/Steps";
import Testimonial from "../sections/Temoignages";

import React from "react";

const Home = () => {
  return (
    <div className="2xl:w-2/3 lg:w-5/6 mx-auto selection:bg-indigo-700 selection:text-white">
      <Navbar />
      <Header />
      <Steps />
      <Gallery />
      <APropos />
      <Pricing />
      {/* <Testimonial /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
