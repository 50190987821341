import React from "react";
import { Check, Minus } from "lucide-react";

// Pricing data
const pricings = [
  {
    name: "Standart",
    price: "349",
    features: [
      "Choix entre **2** designs",
      "Optimisé SEO",
      "**1 Page**",
      "**1 an** d'hébergement offert",
      "Design responsive",
      "Hébergement sécurisé HTTPS",
    ],
    not_included: [],
  },
  {
    name: "Premium",
    price: "499",
    features: [
      "Choix entre **3** designs",
      "Optimisé SEO",
      "**Jusqu'à 4 Pages**",
      "**3 ans** d'hébergement offert",
      "Design responsive",
      "Hébergement sécurisé HTTPS",
    ],
    not_included: [],
  },
];

// Helper function to parse and bold text wrapped with **
const parseBoldText = (text) => {
  const parts = text.split(/(\*\*.*?\*\*)/);
  return parts.map((part, index) =>
    part.startsWith("**") && part.endsWith("**") ? (
      <strong key={index}>{part.slice(2, -2)}</strong>
    ) : (
      part
    )
  );
};

// PricingCard component
const PricingCard = ({ name, price, features, not_included }) => {
  return (
    <div className="relative z-10 font-poppins p-16 flex flex-col max-w-lg text-center text-gray-900 bg-white rounded-3xl border shadow-lg">
      <h3 className="text-xl text-gray-500">{name}</h3>

      <div className="flex justify-center items-baseline mb-12">
        <span className="text-5xl font-extrabold font-kantumruy">
          {price}€{" "}
          <span className="font-poppins text-gray-500 font-semibold text-xl leading-relaxed">
            TTC
          </span>
        </span>
      </div>

      <ul className="mb-8 space-y-4 text-left">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <Check className="text-blue-500" />
            <span>{parseBoldText(feature)}</span>
          </li>
        ))}
        {not_included.map((item, index) => (
          <li key={index} className="flex items-center space-x-3">
            <Minus className="text-gray-300" />
            <span>{parseBoldText(item)}</span>
          </li>
        ))}
      </ul>
      <a
        href="#contact"
        className="hover:shadow-lg hover:shadow-blue-500/50 duration-500 font-semibold ease-in-out bg-gradient-to-t from-blue-500 via-blue-500 via-60% to-blue-400 text-white bg-primary-600 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Nous contacter
      </a>
    </div>
  );
};

// Pricing component that renders PricingCard components dynamically
const Pricing = () => {
  return (
    <section
      id="tarifs"
      className="text-center py-8 md:py-16 mt-12 md:mt-24 relative mx-auto"
    >
      <span className="font-poppins text-gray-600 block mb-4 uppercase">
        TARIFS
      </span>
      <h2 className="text-kantumruy font-semibold text-3xl md:text-6xl text-gray-900 mb-6 md:mb-12">
        Et combien ça coûte tout ça ?
      </h2>

      {/* Gradient Background */}
      <div className="absolute inset-x-0 -top-20 md:top-56 bottom-0 flex justify-center items-center">
        <div className="hidden md:block w-96 h-48 scale-[200%] -z-10 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full blur-3xl opacity-35"></div>
      </div>

      <div className="py-8 px-4 max-w-screen-xl relative z-10">
        {/* Flexbox container for centering and equal height cards */}
        <div className="flex flex-col md:flex-row md:justify-center md:space-x-6 xl:space-x-10 space-y-8 md:space-y-0 items-center md:items-stretch mx-auto">
          {pricings.map((pricing, index) => (
            <PricingCard
              key={index}
              name={pricing.name}
              description={pricing.description}
              price={pricing.price}
              features={pricing.features}
              not_included={pricing.not_included}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
